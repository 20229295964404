import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Grid, Tag } from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import navigationConfig from 'configs/NavigationConfig';
import { connect, useSelector } from 'react-redux';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import utils from 'utils';
import { onMobileNavToggle } from 'redux/slices/themeSlice';
import './custom.scss';
import { ExportOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const isSandBox = process.env.NODE_ENV === 'development';

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const { user } = useSelector((state) => state.auth);
  const [selectedKey, setSelectedKey] = React.useState('dashboard');
  const [items, setItems] = React.useState(navigationConfig);

  const location = useLocation();
  React.useEffect(() => {
    setSelectedKey(routeInfo?.key);
  }, [routeInfo?.key]);

  useEffect(() => {
    window.location.pathname.includes(selectedKey) && setSelectedKey(selectedKey);
  }, [window.location.pathname]);

  const onDocClick = (routeName) => {
    if (routeName == 'DOCUMENTATION') window.open('https://docs.apacepayments.com', '_blank');
  };

  return (
    <div style={{ height: '100%' }}>
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
        mode="inline"
        forceSubMenuRender={true}
        style={{
          borderRight: 0,
          backgroundColor: '#20293A',
          marginTop: props.isMenuOpen ? 125 : 0,
        }}
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      >
        {items
          .filter((menu) => !menu.hide)
          .map((menu) =>
            menu.submenu.length > 0 && user.twoStepVerify ? (
              <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)}>
                {menu.submenu.map((subMenuFirst) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                      key={subMenuFirst.key}
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) => (
                        <Menu.Item key={subMenuSecond.key}>
                          {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                          <span>{setLocale(localization, subMenuSecond.title)}</span>
                          <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={subMenuFirst.key}>
                      {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                      <span>{setLocale(localization, subMenuFirst.title)}</span>
                      <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                    </Menu.Item>
                  )
                )}
              </Menu.ItemGroup>
            ) : menu.title != 'PAYMENTS' ? (
              <Menu.Item
                key={menu.key}
                className="customSelected d-flex align-items-center m-0 font-weight-bold"
              >
                {menu.icon ? <Icon type={menu?.icon} /> : null}

                <span
                  onClick={() => {
                    onDocClick(menu.title);
                  }}
                >
                  {setLocale(localization, menu?.title)}
                  {menu.disabled ? (
                    <Tag
                      color="gold"
                      style={{
                        borderRadius: 50,
                        marginLeft: 10,
                        fontSize: 10,
                        height: 24,
                        padding: '0px 10px',
                      }}
                    >
                      Coming soon
                    </Tag>
                  ) : null}
                </span>

                {menu.path && !menu.disabled && menu.key !== 'documentation' ? (
                  <Link onClick={() => closeMobileNav()} to={menu.path} />
                ) : menu.path && !menu.disabled && menu.key === 'documentation' ? (
                  <a href={menu.path} target="_blank" />
                ) : null}
              </Menu.Item>
            ) : (
              <></>
            )
          )}
      </Menu>
      <div style={{ position: 'fixed', bottom: 0, textAlign: 'center', width: 260 }}>
        <div className={`prod-button ${isSandBox ? 'hover-color' : ''}`}>
          {isSandBox ? (
            <a href="https://merchant.apacepayments.com/auth/signup" target="_blank">
              LOGIN TO PRODUCTION <ExportOutlined className="ml-2" />
            </a>
          ) : (
            <Tag color="geekblue" className="beta-tag">
              BETA v1.0.1
            </Tag>
          )}
        </div>
      </div>
    </div>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: '#20293A !important' }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.path}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
