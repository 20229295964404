import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import MenuContent from './MenuContent';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from 'configs/AppConfig';

const { Sider } = Layout;

export const SideNav = ({
  navCollapsed,
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  localization = true,
}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };
  const [currentUser, setCurrentUser] = React.useState({});
  const { user } = useSelector((state) => state.auth);
  const [isMenuOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    setCurrentUser(user);
  }, [user]);
  return (
    <Sider
      className={`text-white d-print-none ${sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''}`}
      width={260}
      collapsed={navCollapsed}
      id="dropdownNav"
      style={{ backgroundColor: '#20293A' }}
    >
      <div style={{ position: 'fixed' }}>
        {currentUser && currentUser.email && (
          <AvatarStatus
            className="m-5"
            vertical
            white
            src={API_BASE_URL + currentUser.profilePictureUrl}
            name={`${currentUser.ownerFirstName + ' ' + currentUser.ownerLastName}`}
            subTitle={currentUser.email}
            commercialName={currentUser.commercialName}
            size={70}
            setOpen={setOpen}
            isMenuOpen={isMenuOpen}
          />
        )}
        <MenuContent type={NAV_TYPE_SIDE} {...props} isMenuOpen={isMenuOpen} />
      </div>
    </Sider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } = theme;
  return { navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps)(SideNav);
