import fetch from "auth/FetchInterceptor";

const UserSettingsServices = {};

UserSettingsServices.changePassword = function (data) {
  return fetch({
    url: `/vendors/change-password`,
    method: "post",
    data,
  });
};

UserSettingsServices.updateUserData = function (vendorId, data) {
  return fetch({
    url: `/vendors/${vendorId}`,
    method: "put",
    data,
  });
};

UserSettingsServices.uploadImage = function (data) {
  return fetch({
    method: "post",
    url: "/storage",
    data,
  });
};

export default UserSettingsServices;
