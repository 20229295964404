
import { createSlice } from '@reduxjs/toolkit'
import revenueShareService from 'services/RevenueShare';

const initialState = {
  availableRevenueShareAmount: 0,
  feeMinimum: 0,
  feePercentage: 0,
  feePercentageRevenueShare: 0,
  revenueSharePercentage: 0,
  totalPayoutAmount: 0,
  totalPayoutCurrentMonth: 0,

  revenueShares: {
    loading: false,
    revenueShares: 0,
    totalPages: 0,
    totalCount: 0,
    nextCursor: null,
    availableCount: 0,
    unavailableCount: 0
  },

  payouts: {
    payouts: [],
    totalPages: 0,
    totalCount: 0,
    nextCursor: null,
  }
};

export const revenueShareSlice = createSlice({
  name: 'reveueShare',
  initialState,
  reducers: {
    setAvailableRevenueShareAmount(state, action) {
      state.availableRevenueShareAmount = parseFloat(action.payload) > 0 ? parseFloat(action.payload) : 0;
    },
    setRevenuShareData(state, action) {
      state.revenueShares = action.payload;
    },
    setRevenueSharesLoading(state, action) {
      state.revenueShares.loading = action.payload;
    },
    setPayoutsData(state, action) {
      state.payouts = action.payload;
    },
    setPayoutAmounts(state, action) {
      state.availableRevenueShareAmount = parseFloat(action.payload.availableRevenueShareAmount || 0) > 0 ?  parseFloat(action.payload.availableRevenueShareAmount || 0) : 0;
      state.feeMinimum = parseFloat(action.payload.feeMinimum) || 0;
      state.feePercentage = parseFloat(action.payload.feePercentage || 0);
      state.feePercentageRevenueShare = parseFloat(action.payload.feePercentageRevenueShare || 0);
      state.revenueSharePercentage = parseFloat(action.payload.revenueSharePercentage || 0)
      state.totalPayoutAmount = parseFloat(action.payload.totalPayoutAmount || 0);
      state.totalPayoutCurrentMonth = parseFloat(action.payload.totalPayoutCurrentMonth || 0);
    }
  },
})

export const { setRevenuShareData, setRevenueSharesLoading, setPayoutAmounts, setPayoutsData, setAvailableRevenueShareAmount } = revenueShareSlice.actions

// Action creators are generated for each case reducer function

export default revenueShareSlice.reducer;

export const getAvailableRevenueShareAmountAction = vendorId => async (dispatch) => {
  try {
    const response = await revenueShareService.getAvailableRevenueAmount(vendorId);
    const amount = response.data.availableRevenueShareAmount;
    if (parseFloat(amount) > 0) {
      dispatch(setAvailableRevenueShareAmount(parseFloat(amount)))
    } else {
      dispatch(setAvailableRevenueShareAmount(0))
    }
  } catch (err) {
    console.log(err);
  }
}

export const payRevenueSharePayoutAction = (vendorId, payoutDetails) => async () => {
  await revenueShareService.payRevenueShareAmount(vendorId, payoutDetails);
}

export const getVendorPayoutsAction = (vendorId, options) => async (dispatch) => {

  try {
    const { data, } = await revenueShareService.getVendorPayouts(vendorId, options)
    const { totalPages, totalCount, nextCursor, payouts } = data
    dispatch(setPayoutsData({
      payouts,
      totalPages,
      totalCount,
      nextCursor
    }))
  } catch (err) {
    console.log(err)

  }
}

export const getTotalPayoutAmountsAction = (vendorId) => async (dispatch) => {
  try {
    const response = await revenueShareService.getTotalPayout(vendorId);
    dispatch(setPayoutAmounts(response.data))
  } catch (err) {
    console.log(err);
  }
}

export const updateRevenueShareEnabledAction = (vendorId, revenueShareEnabled) => async (dispatch) => {
  try {
    await revenueShareService.enableRevenueShare(vendorId, { revenueShareEnabled })
  } catch (err) {
    console.log(err);
  }
}

export const getRevenueSharesAction = (vendorId, options) => async (dispatch) => {
  dispatch(setRevenueSharesLoading(true))
  try {
    const { data } = await revenueShareService.getRevenueShare(vendorId, options);
    dispatch(setRevenuShareData(data))
  } catch (err) {
    console.log(err);
  }
  dispatch(setRevenueSharesLoading(false))
}