import fetch from "auth/FetchInterceptor";

const RefundServices = {};
RefundServices.getAllRefunds = (vendorId, params) => {
  return fetch({
    url: `/vendors/${vendorId}/refunds`,
    method: "get",
    params,
  });
};

RefundServices.getRefundDetails = (vendorId, refundId) => {
  return fetch({
    url: `/vendors/${vendorId}/refunds/${refundId}`,
    method: "get",
  });
};

RefundServices.createRefund = (data) => {
  return fetch({
    url: `/ext/refunds`,
    method: "post",
    data
  });
};

RefundServices.sendEmail = (refundId) => {
  return fetch({
    url: `/ext/refunds/${refundId}/email`,
    method: "post",
  })
}

RefundServices.cancelRefund = (vendorId, refundId) => {
  return fetch({
    url: `/vendors/${vendorId}/refunds/${refundId}/cancel`,
    method: "post",
  });
};

export default RefundServices;
