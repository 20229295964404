import fetch from "auth/FetchInterceptor";

const JwtAuthService = {};

JwtAuthService.login = function (data) {
  return fetch({
    url: "/vendors/login",
    method: "post",
    data: data,
  });
};


JwtAuthService.twoFactor = function (data) {
  return fetch({
    url: "/vendors/login/two-factor",
    method: "post",
    data: data,
  });
};


JwtAuthService.signUp = function (data) {
  return fetch({
    url: "/vendors/auth/signup",
    method: "post",
    data: data,
  });
};


JwtAuthService.forgetPassword = function (receiver) {
  return fetch({
    url: `/vendors/forgot-password/${receiver}`,
    method: "put"
  });
};

JwtAuthService.verifyCodeAndChangePassword = function (receiver, data) {
  return fetch({
    url: `/vendors/forgot-password/${receiver}`,
    method: "post",
    data
  });
}

JwtAuthService.getsignupOptions = function (){
  return fetch({
    url: '/vendors/signup/options',
    method: "get"
  })
}

JwtAuthService.newSignUp = function (data) {
  return fetch ({
    url: '/vendors/signup',
    method: 'post',
    data
  })
}

JwtAuthService.onboard = function (data) {
  return fetch ({
    url: '/vendors/onboard',
    method: 'post',
    data
  })
}

JwtAuthService.onboardUpdate = function (data) {
  return fetch ({
    url: '/vendors/onboard/update',
    method: 'post',
    data
  })
}

JwtAuthService.onboardSubmit = function (data) {
  return fetch ({
    url: '/vendors/onboard/submit',
    method: 'post',
    data
  })
}

JwtAuthService.getAgreement = function () {
  return fetch ({
    url: '/vendors/agreement',
    method: 'get',
  })
}

JwtAuthService.downloadAgreement = function () {
  return fetch ({
    url: '/vendors/agreement/download',
    method: 'get',
  })
}

JwtAuthService.setupDisable = function (vendorId, data) {
  return fetch ({
    url: `/vendors/${vendorId}/setupEnabled`,
    method: 'put',
    data
  })
}

JwtAuthService.getStatus = function (data) {
  return fetch ({
    url: '/vendors/me',
    method: 'get',
    data
  })
}

JwtAuthService.adminLogin = function (data) {
  return fetch({
    url: "/vendors/admin/login",
    method: "post",
    data: data,
  });
};

export default JwtAuthService;
