import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import Logout from '../../../assets/svg/Logout.svg';
import Settings from '../../../assets/svg/Settings.svg';
import './index.scss';

export const UserAndLogoutMenu = ({ user }) => {
  const { commercialName, email } = user;
  return (
    <Menu style={{ borderRadius: '5' }} className="my-s-menu">
      <div
        className="mx-2 mb-3 p-2 px-3"
        style={{ backgroundColor: '#f7f7f7', borderRadius: 7, maxWidth: '100%' }}
      >
        {commercialName && (
          <h4 className="mb-0 pt-1" style={{ border: 'none' }}>
            {commercialName}
          </h4>
        )}
        {email && (
          <p className="text-muted mt-0 mb-0" style={{ fontSize: 12 }}>
            {email}
          </p>
        )}
      </div>
      <Menu.Item className="font-size-md font-weight-semibold" key="0">
        <img src={Settings} alt="arrow" style={{ cursor: 'pointer' }} />
        <Link className="d-inline-block ml-2" style={{ fontSize: 20 }} to="/app/settings">
          Settings
        </Link>
      </Menu.Item>
      <Menu.Item
        className="font-size-md text-danger font-weight-semibold"
        key="1"
        onClick={() => {
          localStorage.removeItem('auth_token');
          setTimeout(() => {
            window.location.reload();
          });
        }}
      >
        <img src={Logout} alt="arrow" style={{ cursor: 'pointer' }} />
        <a style={{ fontSize: 20 }} className="text-danger d-inline-block ml-2">
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );
};
