import fetch from "auth/FetchInterceptor";

const InvoicesServices = {};

InvoicesServices.getInvoices = (vendorId, params) => {
  return fetch({
    url: `/vendors/${vendorId}/invoices`,
    method: "get",
    params,
  });
};

InvoicesServices.getInvoiceRefunds = (vendorId, invoiceId, params) => {
  return fetch({
    url: `/vendors/${vendorId}/invoices/${invoiceId}/refunds`,
    method: "get",
    params,
  });
};

InvoicesServices.applyRevenueShareToInvoice = (vendorId, invoiceId) => {
  return fetch({
    url: `/vendors/${vendorId}/invoices/${invoiceId}/pay-revenue-share`,
    method: "post",
  });
};

InvoicesServices.paySingleInvice = (vendorId, invoiceId, data) => {
  return fetch({
    url: `/vendors/${vendorId}/invoices/${invoiceId}/pay`,
    method: "post",
    data
  });
};

InvoicesServices.payAllInvices = (vendorId) => {
  return fetch({
    url: `/vendors/${vendorId}/invoices/pay-all `,
    method: "post",
  });
};

InvoicesServices.getInvoiceCalculation = (vendorId, invoiceId, PMId) => {
  return fetch({
    url: `/vendors/${vendorId}/invoices/${invoiceId}/fees?paymentMethodId=${PMId}`,
    method: "get",
  });
};

export default InvoicesServices;
