
import { createSlice } from '@reduxjs/toolkit'
import customerService from 'services/CustomerServices';

const initialState = {
  customers: [],
  loading: false,
  totalCount: 0,
  customerRefunds: [],
  refundsTotalCount: 0,
  customersStats: {
    enabledCount: 0,
    disabledCount: 0,
  }
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    setTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setCustomerStats(state, action) {
      state.customersStats = action.payload;
    },
    setCustomerRefunds(state, action) {
      state.customerRefunds = action.payload;
    },
    setRefundsTotalCount(state, action) {
      state.refundsTotalCount = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCustomerRefunds, setRefundsTotalCount, setLoading, setCustomerStats, setCustomers, setTotalCount } = customerSlice.actions

export default customerSlice.reducer;

export const getAllCustomersAction = (vendorId, options) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const { customers, totalCount, stats } = await customerService.getAllCustomers(vendorId, options);
    dispatch(setCustomers(customers));
    dispatch(setTotalCount(totalCount));
    dispatch(setCustomerStats(stats));
  } catch (err) {
    console.log(err)
  }

  dispatch(setLoading(false));
}

export const enableCustomerAction = (vendorId, customerId) => async (dispatch) => {
  dispatch(setLoading(false));
  try {
    await customerService.enableCustomer(vendorId, { customers: [customerId] });
  } catch (err) {
    console.log(err)
  }
  dispatch(setLoading(false));
}

export const disableCustomerAction = (vendorId, customerId) => async (dispatch) => {
  dispatch(setLoading(false));
  try {
    await customerService.disableCustomer(vendorId, { customers: [customerId] });
  } catch (err) {
    console.log(err)
  }
  dispatch(setLoading(false));
}

export const getCustomerRefundsAction = (customerId, options) => async (dispatch) => {
  const { refunds, totalCount } = await customerService.getCustomerRefunds(customerId, options);
  dispatch(setCustomerRefunds(refunds));
  dispatch(setRefundsTotalCount(totalCount));
}