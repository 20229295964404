import querystring from 'querystring'
import fetch from "auth/FetchInterceptor";

const RevenueServices  = {};

RevenueServices.getVendorPayouts = (vendorId, params = {}) => {
    return fetch({
        url: `/vendors/${vendorId}/payouts?${querystring.encode(params)}`,
        method: 'get',
    })
}

RevenueServices.getRevenueShare = (vendorId, params={}) => {
    return fetch({
        url: `/vendors/${vendorId}/revenue-shares?${querystring.encode(params)}`,
        method: 'get',
    })
}

RevenueServices.getAvailableRevenueAmount = (vendorId) => {
    return fetch({
        url: `/vendors/${vendorId}/available-revenue-share`,
        method: 'get',
    })
}

RevenueServices.getTotalPayout = (vendorId) => {
    return fetch({
        url: `/vendors/${vendorId}/payouts/total-amount`,
        method: 'get',
    })
}

RevenueServices.payRevenueShareAmount = (vendorId, data) => {
    return fetch({
        url: `/vendors/${vendorId}/payouts/receive-payment`,
        method: 'post',
        data,
    })
}

RevenueServices.enableRevenueShare = (vendorId,data) => {
    return fetch({
        url: `/vendors/${vendorId}/revenueShareEnabled`,
        method: 'put',
        data,
    })
}

export default RevenueServices;