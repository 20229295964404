import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Col, Layout, Row, Dropdown } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
// import Logo from "./Logo";
// import NavSearch from "./NavSearch";
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/slices/themeSlice';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils';
import { useSelector } from 'react-redux';
import './header.css';
import arrow from '../../assets/svg/dark-down-arrow.svg';
import { UserAndLogoutMenu } from '../shared-components/AvatarStatus/menu';
import useClickOutside from 'components/hooks/outSideClick';
import { updateUserStatusAction } from 'redux/slices/authSlice';
import warningDark from 'assets/svg/Warning-dark.svg';
import { identifyLogRocketUser } from 'redux/slices/logRocketSlice';

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    setModal,
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
  } = props;
  const [searchActive, setSearchActive] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff');
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();

  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  const getVendorStatus = async () => {
    try {
      await dispatch(updateUserStatusAction());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      dispatch(
        identifyLogRocketUser({
          merchantName: user.commercialName,
          email: user.email,
        })
      );
    }
  }, [user?.commercialName, user?.email]);

  useEffect(() => {
    getVendorStatus();
  }, []);

  const [isMenuOpen, setOpen] = useState();

  const close = () => setOpen(false);

  const selectorRef = useRef(null);
  useClickOutside(selectorRef, close);

  return (
    <Header
      className={` app-header ${isMobile && 'app-header-fixed'} position-relative ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Row
          className="nav"
          style={{
            width: `calc(100%)`,
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
          }}
        >
          {!isMobile ? null : (
            <Col>
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {<MenuOutlined className="nav-icon" />}
                </li>
              </ul>
            </Col>
          )}
          {process.env.NODE_ENV === 'development' && (
            <Col
              style={{ marginInline: 'auto', marginLeft: 0, display: 'flex', alignItems: 'center' }}
            >
              <img src={warningDark} className="warning" width="22px" />
              <span className="apace-span">
                {window.innerWidth > 687 ? 'You are currently on the ' : ''}
                Apace SANDBOX environment
              </span>
            </Col>
          )}

          <Col
            ref={selectorRef}
            onClick={() => setOpen(!isMenuOpen)}
            id="dropdownHeader"
            className="notifications"
          >
            <Dropdown
              getPopupContainer={() => document.getElementById('dropdownHeader')}
              overlayClassName=" user-menu"
              overlay={<UserAndLogoutMenu user={user} />}
              trigger={['click']}
              visible={isMenuOpen}
            >
              <>
                <div className="cursor-pointer dropdown">
                  <span className="email">{user.commercialName}</span>
                  <img src={arrow} alt="dropdown menu" />
                </div>
              </>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
