
import { createSlice } from '@reduxjs/toolkit'
import integrationService from 'services/IntegrationServices';

const initialState = {
  keys: [],
  loading: false,
  createKeyResponse: {},
};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    setKeys(state, action) {
      state.keys = action.payload;
    },
    setCreateKeyResponse(state, action) {
      state.createKeyResponse = action.payload;
    }
  },
})

export const { setCreateKeyResponse, setKeys } = integrationSlice.actions

// Action creators are generated for each case reducer function

export default integrationSlice.reducer;

export const getAllKeysAction = () => async (dispatch) => {
  try {
    const data = await integrationService.getAllKeys();
    dispatch(setKeys(data.apiKeys))
  } catch (err) {
    console.log(err)
  }
}

export const createKeyAction = values => async(dispatch) => {
  try {
    const response = await integrationService.createKey(values);
    dispatch(setCreateKeyResponse(response));
  }catch(err) {
    console.log(err);
  }
}

export const deleteKeyAction = publicId => async (dispatch) => {
  try {
    await integrationService.deleteKey(publicId);
  } catch (err) {
    console.log(err);
  }
}