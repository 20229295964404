import { createSlice } from '@reduxjs/toolkit';
import { identifyLogRocketUser } from 'redux/slices/logRocketSlice';
import JwtAuthService from 'services/JwtAuthService';
import reportingService from 'services/ReportingServices';
import userSettingsServices from 'services/UserSettingsServices';
import integrationService from 'services/IntegrationServices';
export const AUTH_TOKEN = 'auth_token';

const initialState = {
  isLogin: true,
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  adminVendorToken: new URLSearchParams(window.location.search).get('t'),
  user: JSON.parse(localStorage.getItem('current_user')),
  signupOptions: {},
  agreementContent: '',
  agreenemntDownloadLink: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUserData(state, action) {
      state.user = action.payload;
    },
    authenticated(state, action) {
      state.loading = false;
      state.redirect = '/';
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    signOutSuccess(state, action) {
      state.token = null;
      state.redirect = '/auth/login';
      state.loading = false;
    },
    signUpSuccess(state, action) {
      state.loading = false;
      state.token = action.payload;
    },
    adminLogin(state, action) {
      state.adminVendorToken = action.payload;
    },
    showAuthMessage(state, action) {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage(state) {
      state.message = '';
      state.showMessage = false;
    },
    showLoading(state) {
      state.loading = true;
    },
    hideLoading(state) {
      state.loading = false;
    },
    changeLoginView(state, action) {
      state.isLogin = action.payload;
    },
    setSignupOptions(state, action) {
      state.signupOptions = action.payload;
    },
    setAgreementContent(state, action) {
      state.agreementContent = action.payload;
    },
    setAgreementDownloadLink(state, action) {
      state.agreenemntDownloadLink = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAgreementContent,
  setAgreementDownloadLink,
  setSignupOptions,
  changeLoginView,
  updateUserData,
  adminLogin,
  authenticated,
  hideAuthMessage,
  hideLoading,
  showAuthMessage,
  showLoading,
  signOutSuccess,
  signUpSuccess,
} = authSlice.actions;

export default authSlice.reducer;

export const loginAction = (values, history) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const response = await JwtAuthService.login(values);
    if (response.success) {
      if (response.twoStepVerify) {
        dispatch(changeLoginView(false));
      } else {
        dispatch(authenticated({ token: response.token, user: response }));
        localStorage.setItem('auth_token', response.token);
        localStorage.setItem('current_user', JSON.stringify(response));
        const enterpriseData = await JSON.parse(localStorage.getItem('enterprise-data'));

        if (enterpriseData) {
          localStorage.removeItem('enterprise-data');
          history.push('/app/merchant/onboard');
        } else {
          history.push(localStorage.getItem('last_active_location'));
          localStorage.removeItem('last_active_location');
        }

        if (process.env.NODE_ENV !== 'development') {
          dispatch(
            identifyLogRocketUser({
              merchantName: response.commercialName,
              email: response.email,
            })
          );
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
  dispatch(hideLoading());
};

export const submitCodeAction = (loginData) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await JwtAuthService.twoFactor(loginData);
    if (response) {
      dispatch(authenticated({ token: response.token, user: response }));
      localStorage.setItem('auth_token', response.token);
      localStorage.setItem('current_user', JSON.stringify(response));
    }
  } catch (err) {
    console.log(err);
  }
  dispatch(hideLoading());
};

export const sendForgotPasswordEmailAction = (email) => async () => {
  await JwtAuthService.forgetPassword(email);
};

export const adminLoginAction = (data, history) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const response = await JwtAuthService.adminLogin(data);
    dispatch(authenticated({ token: response.token, user: response }));
    localStorage.setItem('auth_token', response.token);
    localStorage.setItem('current_user', JSON.stringify(response));
    dispatch(adminLogin(null));
    history.push(localStorage.getItem('last_active_location'));
    localStorage.removeItem('last_active_location');
  } catch (err) {
    console.log(err);
  }
  dispatch(hideLoading());
};

export const signupAction = (data, history) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const { success, signup_token } = await JwtAuthService.newSignUp({
      receiver: data.email,
      password: data.password,
    });
    if (success && signup_token) {
      const res = await JwtAuthService.onboard({
        ...data,
        signup_token,
        signup_secret: process.env.REACT_APP_SIGNUP_SECRET,
      });
      const isEnterprise = ['From100KTo500K', 'From500KTo1M', 'From1MAndUp'].includes(
        data.avg_monthly_refunds
      );

      if (process.env.NODE_ENV !== 'development' && isEnterprise) {
        localStorage.setItem('enterprise-data', JSON.stringify(data));
        history.push('/auth/signup/enterprise');
      } else {
        history.push('/');
      }
    }
  } catch (err) {
    dispatch(hideLoading());
    console.log(err);
    throw err;
  }
  dispatch(hideLoading());
};

export const uploadVendorImageAction = (vendorId, imageBase64) => async (dispatch, getState) => {
  try {
    const user = getState().auth;
    const { url } = await integrationService.uploadImage({
      imageBase64,
    });
    await integrationService.updateURL(vendorId, { logoUrl: url });
    const updatedUser = {
      ...JSON.parse(localStorage.getItem('current_user')),
      logoUrl: url,
    };
    console.log({ url });
    localStorage.setItem('current_user', JSON.stringify(user));
    dispatch(authenticated({ token: localStorage.getItem('auth_token'), user: updatedUser }));
  } catch (err) {
    console.log(err);
  }
};

export const updateUserDataAction = (vendorId, values) => async (dispatch, getState) => {
  try {
    const user = getState().auth.user;

    const response = await userSettingsServices.updateUserData(user.vendorId, {
      ...values,
    });
    dispatch(
      authenticated({
        token: user.token,
        user: { ...user, ...response.vendorData },
      })
    );
    localStorage.setItem('current_user', JSON.stringify({ ...user, ...response.vendorData }));
  } catch (err) {
    console.log(err);
  }
};

export const updateDailyReportsAction = (dailyReportEnabled) => async (dispatch, getState) => {
  const user = getState().auth.user;

  const response = await reportingService.updateDailyReports({
    dailyReportEnabled,
  });

  dispatch(updateUserData({ ...user, dailyReportEnabled: response.data.dailyReportEnabled }));
};

export const updateReportEmailsAction = (reportingData) => async (dispatch, getState) => {
  const user = getState().auth.user;

  const response = await reportingService.updateDailyReportEmails(reportingData);
  dispatch(updateUserData({ ...user, dailyReportEmails: response.data.dailyReportEmails }));
};

export const updateUserStatusAction = () => async (dispatch, getState) => {
  const user = getState().auth.user;

  const response = await JwtAuthService.getStatus();

  if (response.success) {
    dispatch(updateUserData({ ...user, ...response }));
    localStorage.setItem('current_user', JSON.stringify({ ...user, ...response }));
  }
};

export const updateSetupDisabledAction = (vendorId, setupEnabled) => async () => {
  await JwtAuthService.setupDisable(vendorId, { setupEnabled });
};

export const getSignupOptionsAction = () => async (dispatch) => {
  try {
    const response = await JwtAuthService.getsignupOptions();
    if (Object.keys(response).length) {
      dispatch(setSignupOptions(response));
    }
  } catch (err) {
    console.log(err);
  }
};

export const onboardUpdateAction = (data) => async () => {
  await JwtAuthService.onboardUpdate(data);
};

export const onboardSubmitAction = (data) => async () => {
  await JwtAuthService.onboardSubmit(data);
};

export const getAgreementContentAction = () => async (dispatch) => {
  try {
    const agreementContent = await JwtAuthService.getAgreement();
    dispatch(setAgreementContent(agreementContent));
  } catch (err) {
    console.log(err);
  }
};

export const getAgreementDownloadLink = () => async (dispatch) => {
  try {
    const response = await JwtAuthService.downloadAgreement();
    if (response.url) {
      dispatch(setAgreementDownloadLink(response.url));
    }
  } catch (err) {
    console.log(err);
  }
};

export const submitForgotPasswordAction = (email) => async () => {
  await JwtAuthService.forgetPassword(email);
};

export const verifyCodeAndChangePasswordAction = (email, data) => async () => {
  await JwtAuthService.verifyCodeAndChangePassword(email, data);
};

// export const signupAction = (values) => async (dispatch) => {
//   try {

//     await JwtAuthService.signUp(values);
//     const fakeToken = 'fakeToken'
//     dispatch(authenticated({ token: fakeToken }))
//     dispatch(showAuthMessage());
//   } catch (err) {
//     console.log(err);
//   }
// }
