import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route exact path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />
        <Route exact path={`${APP_PREFIX_PATH}/refund`} component={lazy(() => import(`./history`))} />
        <Route exact path={`${APP_PREFIX_PATH}/revenue`} component={lazy(() => import(`./revenue`))} />
        <Route exact path={`${APP_PREFIX_PATH}/merchant/onboard`} component={lazy(() => import(`./merchant-onboarding`))} />
        <Route path={`${APP_PREFIX_PATH}/invoice/:invoiceID`} component={lazy(() => import(`./invoicepage`))} />
        <Route path={`${APP_PREFIX_PATH}/refund/:refundId`} component={lazy(() => import(`./refund-details`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/invoices/:id`} component={lazy(() => import(`./invoicepage`))} />
        <Route path={`${APP_PREFIX_PATH}/invoices`} component={lazy(() => import(`./invocies`))} />
        <Route path={`${APP_PREFIX_PATH}/integration`} component={lazy(() => import(`./integration`))} />
        <Route path={`${APP_PREFIX_PATH}/search`} component={lazy(() => import(`./search`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);