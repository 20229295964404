import { configureStore } from '@reduxjs/toolkit'
import reducers from "../reducers";

function setupStore() {
  const store = configureStore({reducer: reducers})
  return store;
}

const store = setupStore();

export default store;

