import React, { useState } from "react";
import { connect } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import AppViews from "views/app-views";
import { Layout, Grid } from "antd";
import { useHistory } from "react-router-dom";
import navigationConfig from "configs/NavigationConfig";
import AutoLogout from 'components/shared-components/AutoLogout'

import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location }) => {
  const history = useHistory();
  console.log(history);
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const [visible, setVisible] = useState(false);
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <Layout className="align-items-stretch">
      {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <AutoLogout />
      <Layout className="app-container position-relative w-100">
        <HeaderNav isMobile={isMobile} setModal={setVisible} />
        <Layout className="app-layout position-relative" id="dropdownArea">
          <div className={`${visible ? "modal" : ""}`}></div>
          <div
            className={`app-content  ${isNavTop ? "layout-top-nav" : ""}`}
            style={ history?.location.pathname.indexOf('/app/refund/') !== -1 ? {padding: '0', height: '100%'} : {padding: '25px 20px'}}
          >
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
            <Content style={{height: '100%'}}>
              <AppViews />
            </Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
