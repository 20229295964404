import Auth from '../slices/authSlice';
import Theme from '../slices/themeSlice';
import Refunds from '../slices/refundsSlice'
import RevenueShare from '../slices/revenueShareSlice';
import PaymentMethods from '../slices/paymentMethodsSlice';
import Integration from '../slices/integrationSlice';
import Invoices from '../slices/invoicesSlice';
import Customers from '../slices/customersSlice';
import LogRocket from '../slices/logRocketSlice';

const reducers = {
    theme: Theme,
    auth: Auth,
    refunds: Refunds,
    customers: Customers,
    paymentMethods: PaymentMethods,
    invoices: Invoices,
    revenueShare: RevenueShare,
    integration: Integration,
    logRocket: LogRocket
};

export default reducers;