import { createSlice } from '@reduxjs/toolkit'
import { THEME_CONFIG } from 'configs/AppConfig';

const initialState = {
  ...THEME_CONFIG
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleCollapsedNav(state, action) {
      state.navCollapsed = action.payload;
    },
    onNavStyleChange(state, action) {
      state.sideNavTheme = action.payload
    },
    onLocaleChange(state, action) {
      state.locale = action.payload;
    },
    onNavTypeChange(state, action) {
      state.navType = action.payload;
    },
    onTopNavColorChange(state, action) {
      state.topNavColor = action.payload;
    },
    onHeaderNavColorChange(state, action) {
      state.headerNavColor = action.payload;
    },
    onMobileNavToggle(state, action) {
      state.mobileNav = action.payload;
    },
    onSwitchTheme(state, action) {
      state.currentTheme = action.payload;
    }

  },
})

// Action creators are generated for each case reducer function
export const { toggleCollapsedNav, onNavStyleChange, onLocaleChange, onNavTypeChange, onTopNavColorChange, onHeaderNavColorChange, onMobileNavToggle, onSwitchTheme } = themeSlice.actions

export default themeSlice.reducer