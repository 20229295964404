import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  LogRocket: null,
  merchantName: '',
  email: ''
};

export const logRocketSlice = createSlice({
  name: 'logrocket',
  initialState,
  reducers: {
    setLogRocket(state, action) {
      state.LogRocket = action.payload
    },
    identifyLogRocketUser(state, action) {
      if (state.LogRocket) {
        state.email = action.payload.email;
        state.merchantName = action.payload.merchantName;

        state.LogRocket.identify('hrmozg/merchant-portal-fhfyq', {
          merchantName: action.payload.merchantName,
          email: action.payload.email,
        });
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { setLogRocket, identifyLogRocketUser } = logRocketSlice.actions

export default logRocketSlice.reducer