import { createSlice } from '@reduxjs/toolkit'
import refundService from 'services/RefundServices';

const initialState = {
  refunds: [],
  loading: false,
  refund: {},
  filters: {},
  filterInitialValues: {},
  listOfTabs: {},
  totalCount: 0,
  totalAmount: 0,
};

export const refundSlice = createSlice({
  name: 'refund',
  initialState,
  reducers: {
    getRefunds(state, action) {
      state.refunds = action.payload.refunds;
      state.listOfTabs = action.payload.stats;
      state.totalCount = action.payload.totalCount;
      state.totalAmount = action.payload.totalAmount;
    },
    setRefundsFilter(state, action) {
      state.filters = action.payload;
    },
    updateRefundsFilterInitialValues(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    updateRefundsFilter(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setRefund(state, action) {
      state.refund = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setLoading, getRefunds, setRefundsFilter, updateRefundsFilter, updateRefundsFilterInitialValues, setRefund } = refundSlice.actions

export default refundSlice.reducer;

export const cancelRefundAction = (vendorId, refundId) => async (dispatch) => {
  await refundService.cancelRefund(vendorId, refundId);
}

export const sendRefundEmailAction = (refundId) => async (dispatch) => {
  try {
    const response = await refundService.sendEmail(refundId);
    if (!response.email_sent) {
      throw response;
    }
  } catch (err) {
    throw err
  }
}

export const getRefundsAction = (vendorId, options) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await refundService.getAllRefunds(
      vendorId,
      options
    );
    dispatch(getRefunds(response));
  } catch (err) {
    console.log(err)
  }

  dispatch(setLoading(false))
}

export const getRefundDetailsAction = (vendorId, refundId) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { refundData } = await refundService.getRefundDetails(vendorId, refundId);
    dispatch(setRefund(refundData));
  } catch (err) {

  }
  dispatch(setLoading(false));
}