import fetch from "auth/FetchInterceptor";

const PaymentMethodServices = {};

PaymentMethodServices.getPaymentMethods = () => {
    return fetch({
        url: `/vendors/payment-methods`,
        method: 'get',
    })
}

PaymentMethodServices.getVendorCards = () => {
    return fetch({
        url: `/vendors/cards`,
        method: 'get',
    })
}

PaymentMethodServices.setDefaultCard = (data) => {
    return fetch({
        url: `/vendors/cards/default`,
        method: 'post',
        data,
    })
}

PaymentMethodServices.setRevenueCard = (data) => {
    return fetch({
        url: `/vendors/cards/revenue`,
        method: 'post',
        data,
    })
}

PaymentMethodServices.setDefaultBankAccount = (data) => {
    return fetch({
        url: `/vendors/bank-accounts/default`,
        method: 'post',
        data,
    })
}

PaymentMethodServices.addCard = (data) => {
    return fetch({
        url: `/vendors/cards`,
        method: 'post',
        data,
    })
}

PaymentMethodServices.deleteCard = (id) => {
    return fetch({
        url: `/vendors/cards/${id}`,
        method: 'delete',
    })
}

PaymentMethodServices.getBankAccounts = () => {
    return fetch({
        url: `/vendors/bank-accounts`,
        method: 'get',
    })
}

PaymentMethodServices.addBankAccount = (data) => {
    return fetch({
        url: `/vendors/bank-accounts`,
        method: 'post',
        data,
    })
}

PaymentMethodServices.deleteBankAccount = (id) => {
    return fetch({
        url: `/vendors/bank-accounts/${id}`,
        method: 'delete',
    })
}

export default PaymentMethodServices;