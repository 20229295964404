import fetch from "auth/FetchInterceptor";

const PaymentsServices = {};

PaymentsServices.getToken = (vendorId, data) => {
  return fetch({
    url: `/vendors/${vendorId}/payment-token`,
    method: "post",
    data,
  });
};

PaymentsServices.getCards = (vendorId, params) => {
  return fetch({
    url: `/vendors/${vendorId}/payment-methods`,
    method: "get",
    params,
  });
};

PaymentsServices.deletePayment = (vendorId, fundingSource) => {
  return fetch({
    url: `vendors/${vendorId}/payment-methods/?fundingSource=${fundingSource}`,
    method: "delete",
  });
};

PaymentsServices.addMethod = (vendorId, data) => {
  return fetch({
    url: `/vendors/${vendorId}/payment-methods`,
    method: "post",
    data,
  });
};

PaymentsServices.updateMethods = (vendorId) => {
  return fetch({
    url: `/vendors/${vendorId}/payment-methods`,
    method: "put",
  });
};

PaymentsServices.setDefaultMethod = (vendorId, data) => {
  return fetch({
      url: `/vendors/${vendorId}/payment-methods/default`,
      method: 'post',
      data
  })
}

PaymentsServices.allowAutopay = (vendorId, data) => {
  return fetch({
      url: `/vendors/${vendorId}/allow_autopay`,
      method: 'put',
      data
  })
}

export default PaymentsServices;
