import fetch from "auth/FetchInterceptor";

const ReportingServices = {};

ReportingServices.updateDailyReports = function (data) {
  return fetch({
    method: "put",
    url: "/vendors/settings/dailyReportEnabled",
    data,
  });
};

ReportingServices.updateDailyReportEmails = (data) => {
  return fetch({
    method: 'put',
    url: '/vendors/settings/dailyReportEmails',
    data
  })
}

export default ReportingServices;
