import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { notification } from "antd";
import { AUTH_TOKEN } from "redux/slices/authSlice";

const getNotificationStyle = {
  color: 'rgb(26, 51, 83)',
  border: '1px solid #ffa39e',
  backgroundColor: '#fff1f0'
};

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    // if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
    // history.push(ENTRY_ROUTE);
    // }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
      style: getNotificationStyle,
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "Something wrong",
      style: getNotificationStyle,
    };

    // Remove token and redirect
    if (error.response?.status === 400 || error.response?.status === 403) {
      notificationParam.message = "Authentication Fail";
      notificationParam.description =
        error.response.data.message ||
        error.response.data.errors[0].msg ||
        "Something wrong";
    }

    if (error.response?.status === 401) {
      notification.message = "You are not Authorized";
      notification.description =
        error.response.data.message || error.response.data.errors[0].msg;
      localStorage.removeItem("auth_token");
      // history.push(ENTRY_ROUTE);
      window.location.reload()
    }

    if (error.response.status === 404) {
      notificationParam.message = "Not Found";
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";

      if (error.response && error.response?.data) {
        notificationParam.message = error.response?.data?.message
      }
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }

    if (error.response.status === 429) {
      notificationParam.message = "Too many wrong authentication requests, please wait for sometime before retrying";
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
