import React from 'react';
import { notification } from 'antd';

class AutoLogout extends React.Component {
  constructor(props) {
    super(props);

    this.state = { logginStatus: true };
    this.events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.warnTimeout = setTimeout(this.warn, 3540 * 1000);
    this.logoutTimeout = setTimeout(this.logout, 3600 * 1000);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  warn() {
    const content = {};
    content.message = 'warnning';
    content.description = 'You will be logged out automatically in 1 minute.';
    notification.warning(content);
  }

  logout() {
    // Send a logout request to the API
    this.setState({ logginStatus: false });
    localStorage.removeItem('auth_token');
    localStorage.setItem('last_active_location', window.location.pathname);
    setTimeout(() => {
      window.location.reload();
    });
    // this.destroy(); // Cleanup
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }
  render() {
    return <></>;
  }
}

export default AutoLogout;
