import fetch from "auth/FetchInterceptor";

const IntegrationServices = {};

IntegrationServices.createKey = (data) => {
  return fetch({
    url: "/vendors/keys",
    method: "post",
    data,
  });
};

IntegrationServices.getAllKeys = () => {
  return fetch({
    url: "/vendors/keys",
    method: "get",
  });
};

IntegrationServices.deleteKey = (publicId) => {
  return fetch({
    url: `/vendors/keys/${publicId}`,
    method: 'delete'
  })
}

IntegrationServices.uploadImage = function (data) {
  return fetch({
    method: "post",
    url: "/storage",
    data,
  });
};

IntegrationServices.updateURL = function (vendorId, data) {
  return fetch({
    method: 'put',
    url: `/vendors/${vendorId}/logoUrl`,
    data
  })
}

export default IntegrationServices;
