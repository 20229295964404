import { createSlice } from '@reduxjs/toolkit'
import invoiceService from 'services/InvoicesServices';

const initialState = {
  invoices: [],
  invoice: null,
  invoiceRefunds: [],
  invoiceRevenueShareAmount: 0,
  filters: {},
  listOfTabs: {},
  totalCount: 0,
  totalAmount: 0,
  nextInvoiceData: {},
  cardEstimatedFees: 0,
};

export const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    updateInvoicesFilter(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    updateInvoicesData(state, action) {
      state.invoices = action.payload.invoices;
      state.listOfTabs = action.payload.stats;
      state.totalCount = action.payload.totalCount;
      state.totalAmount = action.payload.totalAmount;
    },
    setInvoices(state, action) {
      state.invoices = action.payload
    },
    setInvoice(state, action) {
      state.invoice = action.payload;
    },
    setInvoiceRefunds(state, action) {
      state.invoiceRefunds = action.payload;
    },
    setInvoiceRevenueShareAmount(state, action) {
      state.invoiceRevenueShareAmount = action.payload
    },
    setNextInvoiceData(state, action) {
      state.nextInvoiceData = action.payload;
    },
    setTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setCardEstimatedFees(state, action) {
      state.cardEstimatedFees = action.payload;
    }
  },
})

export const { setCardEstimatedFees, setTotalCount, setNextInvoiceData, setInvoices, updateInvoicesFilter, updateInvoicesData, setInvoice, setInvoiceRefunds, setInvoiceRevenueShareAmount } = invoiceSlice.actions

// Action creators are generated for each case reducer function

export default invoiceSlice.reducer;

export const getInvoicesAction = (vendorId, options) => async (dispatch) => {
  const { invoicingSummary, invoices, totalCount, totalAmount, stats } = await invoiceService.getInvoices(vendorId, options);
  dispatch(updateInvoicesData({
    invoices,
    stats,
    totalCount,
    totalAmount,
  }))
  dispatch(setNextInvoiceData(invoicingSummary))


}

export const getInvoiceDetailsAction = (vendorId, invoiceId) => async (dispatch) => {
  try {
    const { refunds, invoiceRevenueShareAmount, invoice } = await invoiceService.getInvoiceRefunds(vendorId, invoiceId);
    dispatch(setInvoice(invoice));
    dispatch(setInvoiceRefunds(refunds))
    dispatch(setInvoiceRevenueShareAmount(Number(invoiceRevenueShareAmount).toFixed(2)))
  } catch (err) {
    console.log(err)
  }
}

export const payInvoiceAction = (vendorId, invoiceId, paymentMethodId) => async (dispatch) => {
  const response = await invoiceService.paySingleInvice(vendorId, invoiceId, { paymentMethodId });
  if (!response.success) {
    throw new Error();
  }
  dispatch(getInvoiceDetailsAction(vendorId, invoiceId));
}

export const applyRevenueShareToInvoiceAction = (vendorId, invoiceId) => async (dispatch) => {
  const response = await invoiceService.applyRevenueShareToInvoice(vendorId, invoiceId)
  if (!response.success) {
    throw new Error()
  }

  dispatch(getInvoiceDetailsAction(vendorId, invoiceId));

}

export const getCardEstimatedFeesAction = (vendorId, invoiceId, paymentMethodId) => async (dispatch) => {
  try {
    const fees = await invoiceService.getInvoiceCalculation(vendorId, invoiceId, paymentMethodId);
    console.log({invoiceFees:fees.invoiceFees})
    dispatch(setCardEstimatedFees(fees.invoiceFees));
  } catch (err) {
    console.log(err)
  }
}

export const payAllInvoicesAction = (vendorId) => async(dispatch) => {
  try {
    const response = await invoiceService.payAllInvices(vendorId);
  }catch(err) {

  }
}