import React from "react";
import { Drawer } from "antd";
import { connect } from "react-redux";
import { NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { Scrollbars } from "react-custom-scrollbars";
import MenuContent from "./MenuContent";
import { onMobileNavToggle } from "redux/slices/themeSlice";
import Flex from "components/shared-components/Flex";
import { CloseOutlined } from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import { API_BASE_URL } from "configs/AppConfig";
import { useSelector } from "react-redux";

export const MobileNav = ({
  sideNavTheme,
  mobileNav,
  onMobileNavToggle,
  routeInfo,
  hideGroupTitle,
  localization = true,
}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };
  const { user } = useSelector((state) => state.auth);
  const onClose = () => {
    onMobileNavToggle(false);
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 0, backgroundColor: "#3F4A65" }}
    >
      <div id="dropdownNav" className="w-100 position-relative">
        <Flex flexDirection="column" className="h-100">
          <Flex justifyContent="evenly" alignItems="center">
            <div className="nav-close" onClick={() => onClose()}>
              <CloseOutlined style={{ color: "#fff" }} />
            </div>
          </Flex>
          <AvatarStatus
            className="m-2"
            clickable
            vertical
            white
            src={`${user &&  user.id ? API_BASE_URL + user.profilePictureUrl : ''}`}
            name={`${user.ownerFirstName} ${user.ownerLastName}`}
            subTitle={user.email}
            commercialName={user.commercialName}
            size={70}
          />
          <div className="mobile-nav-menu">
            <Scrollbars autoHide>
              <MenuContent type={NAV_TYPE_SIDE} {...props} />
            </Scrollbars>
          </div>
        </Flex>
      </div>
    </Drawer>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme, mobileNav } = theme;
  return { navCollapsed, sideNavTheme, mobileNav };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MobileNav);
