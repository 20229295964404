import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../../assets/svg/logo.svg';
import light from '../../../assets/sandbox/light.png';
import './index.scss';

export const AvatarStatus = (props) => {
  const { vertical, setOpen, isMenuOpen } = props;
  const isSandBox = process.env.NODE_ENV === 'development';
  return (
    <div
      className={`avatar-status d-flex align-items-center  ${
        vertical ? 'flex-column text-center' : 'flex-row'
      }`}
      style={{ marginBottom: 20 }}
    >
      <img
        src={logo}
        alt="arrow"
        style={{
          height: 60,
          cursor: 'pointer',
          margin: '24px 0 0',
        }}
      />
      {isSandBox && <p style={{ fontSize: 15, color: 'white', opacity: 0.7 }}>SANDBOX</p>}
    </div>
  );
};

AvatarStatus.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
  onNameClick: PropTypes.func,
};

export default AvatarStatus;
