import fetch from "auth/FetchInterceptor"

const CustomerServices  = {};

CustomerServices.getAllCustomers = (vendorId, params) => {
    return fetch({
        url: `/vendors/${vendorId}/customers`,
        method: "get",
        params
    })
}

CustomerServices.getCustomerRefunds = (customerId, params) => {
    return fetch({
        url: `/customers/${customerId}/refunds`,
        method: 'get',
        params
    })
}

CustomerServices.enableCustomer = (vendorId, data) => {
    return fetch({
        url: `/vendors/${vendorId}/customers/disabled`,
        method: 'delete',
        data
    })
}

CustomerServices.disableCustomer = (vendorId, data) => {
    return fetch({
        url: `/vendors/${vendorId}/customers/disabled`,
        method: 'post',
        data
    })
}

export default CustomerServices;