import { createSlice } from '@reduxjs/toolkit'
import paymentsService from 'services/PaymentsServices';
import paymentMethodService from 'services/PaymentMethodServices';

const initialState = {
  cards: [],
  bankAccounts: [],
  defaultCard: null,
  paymentMethods: [],
  cardFees: '',
  defaultPaymentMethod: null,
  paymentToken: null,
};

export const paymentMethodSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setPaymentMethods(state, action) {
      state.paymentMethods = action.payload.paymentMethods;
      state.cards = action.payload.paymentMethods.filter(({ type }) => type !== 'bank');
      state.bankAccounts = action.payload.paymentMethods.filter(({ type }) => type === 'bank');
      state.cardFees = action.payload.fees.card;
    },
    setDefaultPaymentMethod(state, action) {
      state.defaultPaymentMethod = action.payload;
    },
    deleteCard(state, action) {
      state.cards = state.cards.filter(card => card.id !== action.payload);
      state.paymentMethods = state.paymentMethods.filter(PM => PM.id !== action.payload)
    },
    deleteBankAccount(state, action) {
      state.bankAccounts = state.bankAccounts.filter(bankAccount => bankAccount.id !== action.payload);
      state.paymentMethods = state.paymentMethods.filter(PM => PM.id !== action.payload)
    },
    setPaymentToken(state, action) {
      state.paymentToken = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPaymentToken, setPaymentMethods, setDefaultPaymentMethod, deleteCard, deleteBankAccount } = paymentMethodSlice.actions
export default paymentMethodSlice.reducer;

export const updateAllowAutopayAction = (vendorId, allow_autopay) => async () => {
  await paymentsService.allowAutopay(vendorId, { allow_autopay });
}


export const getPaymentMethodsAction = () => async (dispatch) => {
  try {
    const response = await paymentMethodService.getPaymentMethods()
    if (response.success) {
      dispatch(setPaymentMethods(response))
    }
  } catch (err) {
    console.log(err)
  }
}

export const setDefaultPaymentMethodAction = (PMId) => async (dispatch, getState) => {
  const PM = getState()?.paymentMethods?.paymentMethods?.find(({ id }) => id === PMId)

  if (PM.type !== 'bank') {
    const response = await paymentMethodService.setDefaultCard({ cardId: PM.vendorCardId });

    if (response.success) {
      dispatch(setDefaultPaymentMethod(PM));
    }
  } else {
    const response = await paymentMethodService.setDefaultBankAccount({ bankAccountId: PM.vendorBankAccountId });

    if (response.success) {
      dispatch(setDefaultPaymentMethod(PM));
    }
  }
};

export const setRevenuePaymentMethodAction = (PMId) => async (dispatch, getState) => {
  const PM = getState()?.paymentMethods?.paymentMethods?.find(({ id }) => id === PMId)
  const response = await paymentMethodService.setRevenueCard({ cardId: PM.vendorCardId });

  if (response.success) {
    dispatch(getPaymentMethodsAction())
  }
}

export const autoSetDefaultPaymentMethodAction = () => async (dispatch, getState) => {
  const { user } = getState()?.auth
  const { defaultPaymentMethod, paymentMethods } = getState()?.paymentMethods
  if (!defaultPaymentMethod && user?.defaultPaymentMethodId) {
    const PM = paymentMethods?.find(({ id }) => id === user.defaultPaymentMethodId);
    dispatch(setDefaultPaymentMethod(PM));
  } else if (!defaultPaymentMethod && paymentMethods.length === 1 && !user?.defaultPaymentMethodId) {
    dispatch(setDefaultPaymentMethodAction(paymentMethods[0]?.id));
  }
}

export const deleteCardAction = cardId => async (dispatch) => {
  await paymentMethodService.deleteCard(cardId);
  dispatch(deleteCard(cardId));
  dispatch(getPaymentMethodsAction())
}

export const deleteBankAccountAction = (bankAccountId) => async (dispatch) => {
  await paymentMethodService.deleteBankAccount(bankAccountId);
  dispatch(deleteBankAccount(bankAccountId));
  dispatch(getPaymentMethodsAction())
}
export const getPaymentTokenAction = (vendorId, options) => async (dispatch) => {
  try {
    const { paymentToken } = await paymentsService.getToken(vendorId, options);
    dispatch(setPaymentToken(paymentToken))
  } catch (err) {

  }
}

export const addBankAccountAction = data => async () => {
  await paymentMethodService.addBankAccount(data);
}